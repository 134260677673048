import { Subscription } from "@/types/entities";

const mapping: Record<
    Subscription["plan"],
    {
        labels: Subscription["labels"];
        brands: Subscription["brands"];
        hasMarketing: Subscription["hasMarketing"];
        hasPriorityAccess: Subscription["hasPriorityAccess"];
        media: Subscription["media"];
    }
> = {
    free: {
        brands: 1,
        labels: 3,
        media: { images: 1, storage: "1GB" },
        hasMarketing: false,
        hasPriorityAccess: false,
    },
    basic: {
        brands: 3,
        labels: 25,
        media: { images: 3, storage: "1GB" },
        hasMarketing: true,
        hasPriorityAccess: false,
    },
    professional: {
        brands: 5,
        labels: 50,
        media: { images: 5, storage: "5GB" },
        hasMarketing: true,
        hasPriorityAccess: false,
    },
    enterprise: {
        brands: 10,
        labels: 100,
        media: { images: 10, storage: "10GB" },
        hasMarketing: true,
        hasPriorityAccess: true,
    },
    custom: {
        brands: 10,
        labels: 100,
        media: { images: 10, storage: "10GB" },
        hasMarketing: true,
        hasPriorityAccess: true,
    },
};

export { mapping };
