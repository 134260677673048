import React, { useEffect } from "react";
import { Styled } from "../styles";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import constant from "@/utils/constant";
import { Subscription } from "@/types/entities";
import { Controller, useForm } from "react-hook-form";
import { Forms } from "@/types/form";
import schemas from "@/utils/schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select, Input } from "@dippi/ui";
import { SELECT_ITEMS } from "@/utils/helpers";
import { mapping } from "./const";
import { useSubmit } from "./hooks";

interface Props {
    subscription: Subscription;
}

const Form: React.FC<Props> = ({ subscription }) => {
    const defaults = {
        plan: subscription.plan,
        brands: subscription.brands,
        labels: subscription.labels,
        hasMarketing: subscription.hasMarketing,
        hasPriorityAccess: subscription.hasPriorityAccess,
        productGroups: subscription.productGroups,
        media: subscription.media,
    };

    const { control, handleSubmit, reset, watch, setValue } = useForm<Forms["Subscription"]>({
        resolver: yupResolver(schemas.subscription),
        values: defaults,
    });
    const { loading, onSubmit } = useSubmit();

    const plan = watch("plan");
    const disabled = plan !== "custom";

    useEffect(() => {
        if (plan === defaults.plan) {
            setValue("brands", defaults.brands);
            setValue("labels", defaults.labels);
            setValue("media", defaults.media);
            setValue("hasMarketing", defaults.hasMarketing);
            setValue("hasPriorityAccess", defaults.hasPriorityAccess);
        } else {
            setValue("brands", mapping[plan].brands);
            setValue("labels", mapping[plan].labels);
            setValue("media", mapping[plan].media);
            setValue("hasMarketing", mapping[plan].hasMarketing);
            setValue("hasPriorityAccess", mapping[plan].hasPriorityAccess);
        }
    }, [plan]);

    return (
        <Grid
            container
            item
            component="form"
            onSubmit={handleSubmit(async form => await onSubmit(form))}
        >
            <Styled.Content item container xs={12} gap={constant.GRID_SPACING}>
                <Grid item xs={12}>
                    <Typography variant="h3">Subscription information</Typography>
                </Grid>
                <Grid item xl={2} md={2.5} sm={4} xs={12}>
                    <Select
                        control={control}
                        name="plan"
                        label="Plan"
                        id="sub-plan"
                        items={SELECT_ITEMS.subscriptionPlans}
                        fullWidth={true}
                        onChange={() => {
                            console.log("change plan");
                        }}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <Input
                        control={control}
                        name="brands"
                        label="Brands"
                        id="sub-brands"
                        fullWidth={true}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <Input
                        control={control}
                        name="labels"
                        label="Digital labels"
                        id="sub-labels"
                        fullWidth={true}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item container xs={12} gap={constant.GRID_SPACING}>
                    <Grid item xl={2} md={3} sm={4} xs={12}>
                        <Controller
                            name="hasMarketing"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                disableRipple={true}
                                                disabled={disabled}
                                            />
                                        }
                                        label="Has access to marketing"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xl={2} md={3} sm={4} xs={12}>
                        <Controller
                            name="hasPriorityAccess"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                disableRipple={true}
                                                disabled={disabled}
                                            />
                                        }
                                        label="Has priority access"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xl={6} md={6} sm={10} xs={12}>
                        <Select
                            control={control}
                            name="productGroups"
                            label="Product groups"
                            id="sub-product-groups"
                            items={SELECT_ITEMS.productGroups}
                            fullWidth={true}
                            multiple={true}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} gap={constant.GRID_SPACING}>
                    <Grid item xl={1.5} md={2} sm={4} xs={12}>
                        <Input
                            control={control}
                            name="media.images"
                            label="Images"
                            id="sub-media-images"
                            fullWidth={true}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xl={1.5} md={2} sm={4} xs={12}>
                        <Input
                            control={control}
                            name="media.storage"
                            label="Storage"
                            id="sub-media-storage"
                            fullWidth={true}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} gap={constant.GRID_SPACING}>
                    <Button type="submit" disabled={loading}>
                        Save
                    </Button>
                    <Button type="button" variant="outlined" onClick={() => reset()}>
                        Cancel
                    </Button>
                </Grid>
            </Styled.Content>
        </Grid>
    );
};

export default Form;
