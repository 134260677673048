import { GridColDef, GridRowSpacingParams } from "@mui/x-data-grid";
import { Styled } from "./styles";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { onHandleLink, shorten } from "@/utils/helpers";
import { useCallback } from "react";
import { classificationCodeToName } from "@/utils/classification/names";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import constant from "@/utils/constant";
import { Warning } from "@dippi/icons";
import { Product } from "@/types/entities";
import { Ingredients, Nutrition } from "@/types/generated/product";

const useTableHelper = () => {
    const theme = useTheme();

    const tooltipStyles = {
        [theme.breakpoints.down("sm")]: {
            maxWidth: "300px",
        },
    };

    const { defaultImageThumbnail } = constant.ASSETS;

    const columns = (): GridColDef[] => {
        return [
            {
                field: "longTitle",
                headerName: "Name",
                headerAlign: "left",
                align: "left",
                flex: 3,
                renderCell: params => {
                    const { eid, longTitle, typeName, media, nutrition, ingredients } = params.row;
                    const url: string | undefined = media && media[0]?.variants[4]?.url;

                    const isAlcohol = typeName.startsWith("11.01") || typeName.startsWith("11.02");
                    const compliant = nutrition && ingredients ? true : false;

                    return (
                        <Styled.Details data-testid="row-details">
                            <Styled.BottleImage
                                sx={{
                                    backgroundImage: `url(${url ?? defaultImageThumbnail})`,
                                    backgroundSize: url ? "fit" : "50%",
                                }}
                            />
                            <Box display="flex" flexDirection="column" marginLeft="5px">
                                <Typography variant="body2" fontWeight="bold">
                                    {shorten(longTitle, 35)}
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        style={{ opacity: 0.7 }}
                                        onClick={e => {
                                            e.stopPropagation(),
                                                onHandleLink("open-eucompliant", eid);
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            margin="0px 2px 0px 0px"
                                            data-testid={eid}
                                            fontFamily="Sono"
                                        >
                                            {eid}
                                        </Typography>
                                        <OpenInNewIcon style={{ fontSize: "14px" }} />
                                    </Box>
                                    {isAlcohol ? (
                                        compliant ? (
                                            <Tooltip title="This product is compliant with Regulation (EU) 2021/2117">
                                                <Styled.EUChip
                                                    label="EU compliant"
                                                    size="small"
                                                    variant="outlined"
                                                    color="success"
                                                    icon={
                                                        <img
                                                            src={`${constant.VITE_CDN_BUCKET_PATH}/static/flags/EU.png`}
                                                            height={18}
                                                            width={18}
                                                            alt="eu flag"
                                                        />
                                                    }
                                                />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="This product is missing information to be compliant with Regulation (EU) 2021/2117">
                                                <Styled.EUChip
                                                    label="EU incompliant"
                                                    size="small"
                                                    variant="outlined"
                                                    color="warning"
                                                    icon={
                                                        <Warning
                                                            width={18}
                                                            height={18}
                                                            fill="#ffb340"
                                                        />
                                                    }
                                                />
                                            </Tooltip>
                                        )
                                    ) : null}
                                </Box>
                            </Box>
                        </Styled.Details>
                    );
                },
            },
            {
                field: "typeName",
                headerName: "Product group",
                headerAlign: "left",
                align: "left",
                sortable: false,
                flex: 2,
                renderCell: params => {
                    const typeName: Product["typeName"] = params.row.typeName;

                    return (
                        <Tooltip
                            title={
                                <Box>
                                    <Typography variant="body2" fontWeight="bold">
                                        {classificationCodeToName[typeName].title}
                                    </Typography>
                                    <Typography variant="caption">Code: {typeName}</Typography>
                                </Box>
                            }
                            placement="bottom-end"
                            componentsProps={{
                                tooltip: { sx: tooltipStyles },
                            }}
                        >
                            <Typography variant="body2">
                                {classificationCodeToName[typeName].title}
                            </Typography>
                        </Tooltip>
                    );
                },
            },
            {
                field: "updatedAt",
                headerName: "Last updated",
                headerAlign: "left",
                flex: 2,
                valueFormatter: ({ value }) => value.slice(0, 10),
            },
            {
                field: "createdAt",
                headerName: "Creation date",
                headerAlign: "left",
                flex: 2,
                valueFormatter: ({ value }) => value.slice(0, 10),
            },
        ];
    };

    const rows = (data: Product[] | undefined) => {
        return data
            ? data.map(product => {
                  const {
                      _id,
                      typeName,
                      eid,
                      longTitle,
                      shortTitle,
                      media,
                      updatedAt,
                      createdAt,
                      categorySpecific,
                  } = product;

                  let nutrition: Nutrition | undefined = undefined;
                  let ingredients: Ingredients | undefined = undefined;
                  if ("nutrition" in categorySpecific && categorySpecific.nutrition) {
                      nutrition = categorySpecific.nutrition;
                  }
                  if ("ingredients" in categorySpecific && categorySpecific.ingredients) {
                      ingredients = categorySpecific.ingredients;
                  }
                  return {
                      _id,
                      typeName,
                      eid,
                      longTitle,
                      shortTitle,
                      ...(nutrition && { nutrition }),
                      ...(ingredients && { ingredients }),
                      media,
                      createdAt,
                      updatedAt,
                  };
              })
            : [];
    };

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 4,
            bottom: params.isLastVisible ? 0 : 4,
        };
    }, []);

    return { columns, rows, getRowSpacing };
};

export { useTableHelper };
