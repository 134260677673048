import * as Yup from "yup";

const isString = (val?: unknown): val is string => {
    return typeof val === "string" && val.length > 0;
};

const isNumber = (val?: unknown): val is number => {
    return typeof val === "number";
};

const isObject = (val: unknown): val is object => {
    return typeof val === "object" && val !== null;
};

const isDefined = <D>(val: D | undefined): val is D => {
    return typeof val !== "undefined";
};

const hasValue = <D>(val: D | null | undefined): val is D => {
    return typeof val !== "undefined" && val !== null;
};

const isArrayWithData = <D>(val: D | undefined): val is D => {
    return Array.isArray(val) && val.length > 0;
};

const isJson = (val: string) => {
    try {
        JSON.parse(val);
        return true;
    } catch (e) {
        return false;
    }
};

const typedKeys = <O extends object>(obj: O) => {
    return Object.keys(obj) as (keyof O)[];
};

Yup.addMethod(Yup.mixed, "validateSubscriptionStats", function (this: Yup.MixedSchema) {
    return this.test("is-valid", "Must be number or unlimited", function (this, value) {
        if (value && !isNaN(+value)) return true;
        if (value && isNaN(+value) && value !== "unlimited") return false;
        return true;
    });
});

export { isString, isNumber, isObject, isDefined, hasValue, isArrayWithData, isJson, typedKeys };
